import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Box } from '@material-ui/core';

const XsProposal = ({ classes, seen, proposal, proposalClass }) => (
  <Card className={classNames(classes.card, { [classes.cardBorder]: !seen })}>
    <CardActionArea
      classes={{
        root: classes.actionArea,
      }}
    >
      <Box display="flex" alignItems="center" width="100%">
        <div
          style={{
            backgroundColor: '#e8e8e8',
            color: '#2e2e2e',
            minWidth: '30px',
            padding: '15px 10px 10px',
            textAlign: 'center',
          }}
        >
          <h3 className="mb-0">{proposal.number}</h3>
        </div>
        <Box ml="10px">
          <h5 className={classes.truncate}>{proposal.name}</h5>
        </Box>
      </Box>
      {/* {proposalClass && (
        <div
          className={classes.classStyle}
          style={{ backgroundColor: proposalClass.colour }}
        />
      )} */}
    </CardActionArea>
  </Card>
);

XsProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  card: {
    width: '16rem',
    // height: '2.125rem',
    borderRadius: 0,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.modeColors.cardBackground,
  },
  cardBorder: {
    width: '5.25rem',
    height: '1.875rem',
    border: '0.125rem solid #f46b1e',
    boxShadow: 'none',
  },
  title: {
    marginBottom: 0,
    paddingTop: '0.3125rem',
    paddingBottom: 1,
    paddingLeft: '0.375rem',
  },
  classStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#ffa632',
    zIndex: 2,
    width: '0.625rem',
    height: '2.125rem',
  },
  truncate: {
    width: '170px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0,
    fontWeight: 400,
    paddingRight: '10px',
  },
});

export default withStyles(styles)(XsProposal);
