import axios from 'axios';
import * as searchHelper from '../../util/searchHelper';
import _ from 'lodash';
import { filtersData, advancedData } from '../../util/Helper';

const getFirebaseCollectionAsObject = async (firestore, collection) => {
  try {
    const dataRef = firestore.collection(collection);
    const data = await dataRef.get();
    return _.reduce(
      data.docs,
      (result, doc, key) => {
        const id = doc.id;
        const data = doc.data();
        return Object.assign(result, { [id]: data });
      },
      {}
    );
  } catch (error) {
    console.log('Could not get data from firebase:', error);
  }
};

const getFirebaseCollectionAsArray = async (firestore, collection) => {
  try {
    const dataRef = firestore.collection(collection);
    const data = await dataRef.get();
    return data.docs.map(doc => Object.assign({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.log('Could not get ordered data from firebase:', error);
  }
};

const chooseSortMethod = (
  sortByValue,
  dataToSort,
  proposalsData,
  classes,
  comments,
  likes
) => {
  switch (sortByValue) {
    case 1:
      return sortFirstToLast(dataToSort);
    case 2:
      return sortLastToFirst(dataToSort);
    case 3:
      return sortByCommentsDesc(dataToSort, comments);
    case 4:
      return sortByCommentsAsc(dataToSort, comments);
    case 5:
      return sortedByCategory(proposalsData, dataToSort, classes);
    case 6:
      return sortNameAToZ(dataToSort);
    case 7:
      return sortNameZToA(dataToSort);
    case 8:
      return sortRandom(dataToSort);
    case 9:
      return sortByMostLikes(dataToSort, likes);
    case 10:
      return sortByLeastLikes(dataToSort, likes);
    default:
      return;
  }
};

export const getProposals = sortValue => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();

    dispatch({
      type: 'FETCH_PROPOSALS_LOADING',
    });

    const dataToFetch =
      state.proposals.selectedPhase === 'phase-1'
        ? process.env.REACT_APP_API_PHASE_1
        : process.env.REACT_APP_API_PHASE_2;
    const proposalsData = await getFirebaseCollectionAsObject(firestore, 'proposals');
    const unOrderedClasses = await getFirebaseCollectionAsArray(
      firestore,
      'PublicClasses'
    );
    const classes = _.orderBy(unOrderedClasses, val => val.position);
    const comments = await getFirebaseCollectionAsObject(firestore, 'comments');
    const likes = await getFirebaseCollectionAsObject(firestore, 'likes');

    axios
      .get(dataToFetch)
      .then(data => {
        // console.log('data:', data)
        const sortedData = chooseSortMethod(
          sortValue,
          data.data.competition.proposals,
          proposalsData,
          classes,
          comments,
          likes
        );
        dispatch({
          type: 'FETCH_PROPOSALS_SUCCESS',
          payload: sortedData || data.data.competition.proposals,
        });
      })
      .catch(err => {
        // console.log('err', err);
        dispatch({
          type: 'FETCH_PROPOSALS_ERROR',
          payload: err,
        });
      });
  };
};

const getFirebaseProposals = async (firestore, collection) => {
  try {
    const dataRef = firestore.collection(collection);
    const data = await dataRef.get();
    return _.reduce(
      data.docs,
      (result, doc, key) => {
        const proposalId = doc.id;
        const proposalData = doc.data();
        return Object.assign(result, { [proposalId]: proposalData });
      },
      {}
    );
  } catch (error) {
    console.log('Could not get proposals data from firebase:', error);
  }
};

export const filterProposals = link => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState(),
      profile = state.firebase.profile,
      initData = state.proposals.initData,
      classes = state.firestore.ordered.PublicClasses;
    const searchQuery = searchHelper.getSearchQuery(link);

    /**
     * In previous implementation, proposalsData = state.firestore.data.proposals.
     * By default, getFirestore() is async/await, so we don't have data from firebase for proposalsData and this leads to one bug
     * After user filter proposals by class name, result comes to UI. Then user reloads page and receives empty page with result 0
     * That's why I need to get data directly from firebase as below
     */
    const proposalsData = await getFirebaseProposals(firestore, 'proposals');

    const publicClass = searchQuery.classes;
    const privateTags = searchQuery.private_tags;
    const publicTags = searchQuery.public_tags;
    const hasNotes = searchQuery.has_notes;
    const show = searchQuery.show;
    const search = searchQuery.search;
    const advancedSearch = searchQuery.advanced_search;
    // const sortValue = Number(window.localStorage.getItem('sort'));
    // console.log('SORT VALUE IN FILTER PROPOSALS:', sortValue);
    // const sortedData = sortValue !== 8 ? chooseSortMethod(sortValue, initData) : initData;

    if (link) {
      if (search) {
        const newData = searchHelper.searchData(search, initData);
        dispatch({
          type: 'SEARCH_PROPOSALS_SUCCESS',
          payload: newData,
        });
      } else {
        if (advancedSearch) {
          let data = filtersData(
            show,
            publicClass,
            privateTags,
            publicTags,
            hasNotes,
            initData,
            profile,
            proposalsData
          );

          data =
            _.isEmpty(data) &&
            !publicClass &&
            !privateTags &&
            !publicTags &&
            !hasNotes &&
            !show
              ? initData
              : data;
          const newData = advancedData(advancedSearch, data);
          dispatch({
            type: 'ADVANCED_SEARCH_SUCCESS',
            payload: newData,
          });
        } else {
          const newData = filtersData(
            show,
            publicClass,
            privateTags,
            publicTags,
            hasNotes,
            initData,
            profile,
            proposalsData
          );
          const sortedNewData = sortedByCategory(proposalsData, newData, classes);
          dispatch({
            type: 'FILTER_PROPOSALS_SUCCESS',
            payload: sortedNewData,
          });
        }
      }
    } else {
      dispatch({
        type: 'FILTER_PROPOSALS_SUCCESS',
        payload: initData,
      });
    }
  };
};

const sortedByCategory = (firebaseData, dataToSort, firebaseClassesData) => {
  let newData = {};
  let results = [];

  // sort the proposals with class
  const classesNames = _.map(firebaseClassesData, val => val.class);
  const proposalsClass = _.pickBy(firebaseData, val => val.class);

  const sortedDataWithSameClassNameByNumber = (comparedClassName, data) => {
    let sortedData = [];

    _.forEach(proposalsClass, (value, key) => {
      if (value.class.class === comparedClassName && data[key]) {
        sortedData.push([key, data[key]]);
      }
    });

    // Ascending order
    return sortedData.sort((a, b) => (a[1].number > b[1].number ? 1 : -1));
  };

  _.forEach(classesNames, (value, index) => {
    const sortedResults = sortedDataWithSameClassNameByNumber(
      classesNames[index],
      dataToSort
    );

    results = [...results, ...sortedResults];
  });

  _.forEach(results, value => (newData[value[0]] = value[1]));

  //add the proposals without classes
  const proposalsClassKey = _.map(newData, (val, key) => key);

  _.forEach(dataToSort, (val, key) => {
    if (!proposalsClassKey.includes(key) && val) {
      newData[key] = val;
    }
  });

  return newData;
};

const sortRandom = obj => {
  const objToArr = _.toPairs(obj) || [];
  let index = objToArr.length;

  while (--index > 0) {
    const randomIndex = Math.floor(Math.random() * (index + 1));
    // swap the elements that are in the selected index and at random index
    [objToArr[index], objToArr[randomIndex]] = [objToArr[randomIndex], objToArr[index]];
  }

  const arrToObj = _.fromPairs(objToArr);
  return arrToObj;
};

const sortNameAToZ = dataToSort =>
  _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => val[1].name.toLowerCase()));

const sortNameZToA = dataToSort =>
  _.fromPairs(
    _.orderBy(_.toPairs(dataToSort), val => val[1].name.toLowerCase(), ['desc'])
  );

export const sortFirstToLast = dataToSort =>
  _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => val[1].number));

const sortLastToFirst = dataToSort =>
  _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => -val[1].number));

const addTotalCommentsIntoProposal = (dataToSort, comments) => {
  return _.map(dataToSort, (proposal, proposalId) => {
    const allComments = _.filter(
      comments,
      (comment, id) => comment?.proposalId === proposalId
    );
    proposal.totalComments = allComments.length;

    return [proposalId, proposal];
  });
};
const sortByCommentsDesc = (dataToSort, comments) => {
  const proposalsWithTotalComments = addTotalCommentsIntoProposal(dataToSort, comments);
  const sortedProposalsWithTotalComments = _.sortBy(
    proposalsWithTotalComments,
    proposal => -proposal[1].totalComments
  );
  const toObj = _.fromPairs(sortedProposalsWithTotalComments);

  return toObj;
};

const sortByCommentsAsc = (dataToSort, comments) => {
  const proposalsWithTotalComments = addTotalCommentsIntoProposal(dataToSort, comments);
  const sortedProposalsWithTotalComments = _.sortBy(
    proposalsWithTotalComments,
    proposal => proposal[1].totalComments
  );
  const toObj = _.fromPairs(sortedProposalsWithTotalComments);

  return toObj;
};

const addTotalLikesIntoProposal = (dataToSort, likes) => {
  return _.map(dataToSort, (proposal, proposalId) => {
    const allLikes = _.filter(likes, (like, id) => like?.proposalId === proposalId);
    proposal.totalLikes = allLikes.length;

    return [proposalId, proposal];
  });
};

const sortByMostLikes = (dataToSort, likes) => {
  const totalLikes = addTotalLikesIntoProposal(dataToSort, likes);
  const sortedProposalsWithTotalLikes = _.sortBy(
    totalLikes,
    proposal => -proposal[1].totalLikes
  );
  const toObj = _.fromPairs(sortedProposalsWithTotalLikes);

  return toObj;
};

const sortByLeastLikes = (dataToSort, likes) => {
  const totalLikes = addTotalLikesIntoProposal(dataToSort, likes);
  const sortedProposalsWithTotalLikes = _.sortBy(
    totalLikes,
    proposal => proposal[1].totalLikes
  );
  const toObj = _.fromPairs(sortedProposalsWithTotalLikes);

  return toObj;
};

export const sortProposals = val => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const { proposals } = state;
    // const { profile } = state.firebase;
    const proposalsData = state.firestore.data.proposals;
    const classes = state.firestore.ordered.PublicClasses;
    const comments = state.firestore.data.comments;
    const likes = state.firestore.data.likes;

    let newData, dataToSort;

    if (proposals.search) {
      dataToSort = proposals.searchProposals;
    } else {
      dataToSort = proposals.data;
    }

    // sort name A to Z
    if (val === 6) {
      newData = sortNameAToZ(dataToSort);
    }

    // sort name Z to A
    if (val === 7) {
      newData = sortNameZToA(dataToSort);
    }

    // sort random
    if (val === 8) {
      newData = sortRandom(dataToSort);
    }

    // sort number first to last
    if (val === 1) {
      newData = sortFirstToLast(dataToSort);
    }

    // sort number last to first
    if (val === 2) {
      newData = sortLastToFirst(dataToSort);
    }

    if (val === 3) {
      newData = sortByCommentsDesc(dataToSort, comments);
    }

    if (val === 4) {
      newData = sortByCommentsAsc(dataToSort, comments);
    }

    if (val === 9) {
      newData = sortByMostLikes(dataToSort, likes);
    }

    if (val === 10) {
      newData = sortByLeastLikes(dataToSort, likes);
    }

    // sort UnSeen First
    // if (val === 3) {
    //   if (profile.proposals) {
    //     const proposals_seen = _.map(
    //       _.pickBy(profile.proposals, val => val.lastSeen),
    //       (val, key) => key
    //     );
    //     newData = _.fromPairs(
    //       _.orderBy(_.toPairs(dataToSort), val => proposals_seen.includes(val[0]))
    //     );
    //   } else {
    //     newData = dataToSort;
    //   }
    // }

    // sort Seen First
    // if (val === 4) {
    //   if (profile.proposals) {
    //     const proposals_seen = _.map(
    //       _.pickBy(profile.proposals, val => val.lastSeen),
    //       (val, key) => key
    //     );
    //     newData = _.fromPairs(
    //       _.orderBy(_.toPairs(dataToSort), val => !proposals_seen.includes(val[0]))
    //     );
    //   } else {
    //     newData = dataToSort;
    //   }
    // }

    // sort by Category
    if (val === 5) {
      if (proposalsData) {
        newData = sortedByCategory(proposalsData, dataToSort, classes);
      } else {
        newData = dataToSort;
      }
    }

    if (proposals.search) {
      dispatch({
        type: 'SORT_SEARCH_PROPOSALS_SUCCESS',
        payload: newData,
      });
    } else {
      dispatch({
        type: 'SORT_ALL_PROPOSALS_SUCCESS',
        payload: newData,
      });
    }
  };
};

export const updateCookies = data => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_PROPOSALS_COOKIES',
      payload: data,
    });
  };
};

export const setPhaseId = phaseId => dispatch =>
  dispatch({ type: 'SET_SELECTED_PHASE', phaseId });

export const setBaseUrlToGetProposalsData = baseUrl => dispatch =>
  dispatch({ type: 'SET_BASE_URL', baseUrl });

export const setViewMode = viewMode => dispatch =>
  dispatch({ type: 'SET_VIEW_MODE', viewMode });

export const setActiveView = activeView => dispatch =>
  dispatch({ type: 'SET_ACTIVE_VIEW', activeView });
