import React from 'react';
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';
import { Document, View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { LineChart, Line, YAxis } from 'recharts';

import RalewayNormal from '../assets/fonts/regular.ttf';
import RalewayBold from '../assets/fonts/bold.ttf';
import * as Helper from '../util/Helper.js';
import { rating_fields } from '../single-proposal/rating_fields';
import PdfFirstPage from './PdfFirstPage';
import PdfSecondPage from './PdfSecondPage';
import KeravaLogo from '../assets/icons/kerava-logo.jpg';

import { useTranslation } from 'react-i18next';

Font.register({
  family: 'Raleway',
  fonts: [
    { src: RalewayNormal }, // font-style: normal, font-weight: normal
    { src: RalewayBold, fontStyle: 'normal', fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  secondPageHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  head_small: {
    fontSize: 8,
    marginBottom: 8,
    color: '#353c43',
    fontWeight: 700,
    fontFamily: 'Raleway',
  },
  head_medium: {
    fontSize: 12,
    marginBottom: 6,
    color: '#353c43',
    fontWeight: 700,
    fontFamily: 'Raleway',
  },
  head_big: {
    fontSize: 28,
    color: '#353c43',
    fontWeight: 700,
  },
});

const ExportPdf = ({
  reactAppUrl,
  selectedPhase,
  isOnePagePdf,
  data,
  proposalsOrdered,
  PublicClasses,
  hasProfile,
}) => {
  const { t, i18n } = useTranslation();

  const getImages = data => {
    const elements = _.filter(data.elements, (value, key) => value.type === 'panel');
    const bigImageUrl = elements[1].pdf_thumbnail_url;
    return (
      <View
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <View style={{ width: '15%' }}>
          {elements.map(({ pdf_thumbnail_url }, i) => {
            const marginTopValue = i === 0 ? 10 : 5;
            if (i < 6 && i !== 1) {
              return (
                <Image
                  key={i}
                  style={{ marginTop: marginTopValue, maxWidth: '100%', height: 86 }}
                  src={reactAppUrl + pdf_thumbnail_url}
                />
              );
            }
            return null;
          })}
        </View>

        <View style={{ width: '75%' }}>
          <Image
            style={{ marginTop: 10, width: '100%', height: 430 }}
            src={reactAppUrl + bigImageUrl}
          />
        </View>
      </View>
    );
  };

  const getPageHeader = (data, isOnePagePdf, isSecondPage) => {
    const currentPhase = selectedPhase === 'phase-2' ? 'Second phase' : 'First phase';

    if (isSecondPage) {
      return (
        <View style={styles.secondPageHeader}>
          <Image
            style={{
              marginTop: 0,
              width: 140,
              maxHeight: 50,
            }}
            src={KeravaLogo}
          />
        </View>
      );
    }

    return (
      <>
        <View style={styles.header}>
          <View>
            <Text style={{ ...styles.head_small, color: '#9f9f9f' }}>
              {t(`${currentPhase} entries`)}
            </Text>
            <Text style={{ ...styles.head_medium, color: '#9f9f9f', marginBottom: 14 }}>
              {t('Niemen ranta-alueen ideakilpailu')}
            </Text>
          </View>

          {(isOnePagePdf || isSecondPage) && (
            <View>
              <Image
                style={{
                  marginTop: 0,
                  width: 140,
                  maxHeight: 50,
                }}
                src={KeravaLogo}
              />
            </View>
          )}
        </View>

        <View
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            flexDirection: 'row',
            marginTop: -6,
            marginBottom: 6,
          }}
        >
          <Text style={styles.head_big}>{data.number}</Text>
          <Text
            style={{
              ...styles.head_medium,
              paddingLeft: 16,
              marginBottom: 4,
              textTransform: 'capitalize',
            }}
          >
            {data.name}
          </Text>
        </View>
      </>
    );
  };

  const gitStatistic = proposal => {
    if (proposal && proposal.excelData) {
      const { statistic } = proposal.excelData;
      return statistic
        ? _.map(statistic, (value, key) => {
            const statisticKey = Object.keys(value)[0];
            const statisticValue = value[Object.keys(value)[0]];
            const statisticValueArr = statisticValue.split(' ');
            if (statisticValueArr[0].indexOf(',') >= 0) {
              statisticValueArr[0] = statisticValueArr[0].replace(',', '.');
            }
            if (/^[0-9]+(\.)?[0-9]*$/.test(statisticValueArr[0])) {
              const floatVal = parseFloat(statisticValueArr[0]);
              if (floatVal && !Number.isInteger(floatVal)) {
                statisticValueArr[0] = Number(statisticValueArr[0]).toFixed(2);
              }
            }
            // Add space between thousands
            statisticValueArr[0] = statisticValueArr[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            // Translate key figure unit
            statisticValueArr[statisticValueArr.length - 1] = t(
              statisticValueArr[statisticValueArr.length - 1]
            );

            return statisticValueArr ? (
              <View
                key={key}
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}
              >
                <Text style={{ fontSize: 8, fontFamily: 'Raleway', width: 130 }}>
                  {t(statisticKey)}:
                </Text>
                <Text style={{ fontSize: 8, fontFamily: 'Raleway' }}>
                  {statisticValueArr.join(' ')}
                </Text>
              </View>
            ) : null;
          })
        : null;
    }
    return null;
  };

  const getLineChartValues = rate => {
    const linesChart = rating_fields.filter(item => item.name !== 'overall');
    const values = linesChart.map(item => {
      const currentRate = rate[item.name] ? rate[item.name] : 0;
      return { ...item, rate: currentRate };
    });

    const rateDiv = (
      <div className="position-absolute">
        <LineChart width={150} height={100} data={values}>
          <Line
            dot={false}
            type="monotone"
            dataKey="rate"
            stroke="#3f51b5"
            strokeWidth="4"
          />
          <YAxis type="number" domain={[0, 5]} hide={true} />
        </LineChart>
      </div>
    );
    const rateHtml = ReactDOMServer.renderToStaticMarkup(rateDiv);
    return rateHtml.substring(rateHtml.lastIndexOf('d=') + 3, rateHtml.lastIndexOf('">'));
  };

  const getPdf = (data, lang) => {
    return _.map(data, (proposalData, id) => {
      const images = getImages(proposalData);
      const statistic = gitStatistic(proposalData);
      const publicReview = Helper.getProposalPublicReview(proposalsOrdered, id, 'en');
      const publicReviewFI = Helper.getProposalPublicReview(proposalsOrdered, id, 'fi');
      // TODO: find a better solution for this problem as user copy paste text into editor with line break and there's no space between words
      const replaceBrWithSpaceEN =
        publicReview && publicReview.includes('<br>')
          ? publicReview.split('<br>').join('&nbsp;')
          : null;
      const replaceBrWithSpaceFI =
        publicReview && publicReview.includes('<br>')
          ? publicReviewFI.split('<br>').join('&nbsp;')
          : null;

      const publicReviewArray = Helper.htmToArray(
        lang === 'fi' ? replaceBrWithSpaceFI : replaceBrWithSpaceEN
      );
      const publicReviewENFontsizeArr =
        publicReview && publicReview.includes('font-size: ')
          ? publicReview.split('font-size: ')
          : ['font-size: ', '16'];
      const fontsizeEN = publicReviewENFontsizeArr[1].split('px')[0];
      const publicReviewFIFontsizeArr =
        publicReviewFI && publicReviewFI.includes('font-size: ')
          ? publicReviewFI.split('font-size: ')
          : ['font-size: ', '16'];
      const fontsizeFI = publicReviewFIFontsizeArr[1].split('px')[0];
      const fontsize = lang === 'fi' ? parseInt(fontsizeFI) : parseInt(fontsizeEN);

      const proposalClass = Helper.getProposalClass(id, proposalsOrdered, PublicClasses);
      const proposalPublicRate = Helper.getProposalPublicRate(proposalsOrdered, id);
      const curvePath = getLineChartValues(proposalPublicRate);

      return (
        <React.Fragment key={id}>
          <PdfFirstPage
            proposalClass={proposalClass}
            statistic={statistic}
            publicReviewArray={publicReviewArray}
            pageHeader={getPageHeader(proposalData, isOnePagePdf, false)}
            proposalData={proposalData}
            curvePath={curvePath}
            proposalPublicRate={proposalPublicRate}
            isOnePagePdf={isOnePagePdf}
            reactAppUrl={reactAppUrl}
            hasProfile={hasProfile}
            fontsize={fontsize}
          />
          {!isOnePagePdf && (
            <PdfSecondPage
              pageHeader={getPageHeader(proposalData, isOnePagePdf, true)}
              images={images}
            />
          )}
        </React.Fragment>
      );
    });
  };

  return <Document id="test">{getPdf(data, i18n.language)}</Document>;
};

export default ExportPdf;
