import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TietoaLogo from '../assets/icons/tietoa-logo.svg';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 20,
    width: '100%',
    zIndex: 1,
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      zIndex: 1000,
    },
  },
  paper: {
    height: 140,
    width: 100,
  },
  logo: {
    width: '25px',
    height: '25px',
    marginRight: '1rem',
  },
  link: {
    textDecoration: 'none',
    color: '#f93838',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#D31A1A',
    },
  },
}));

export default function() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <img src={TietoaLogo} alt="Tietoa logo" className={classes.logo} />
      <p>
        Powered by{' '}
        <a
          href="https://tietoa.fi/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          Tietoa
        </a>
      </p>
    </footer>
  );
}
