import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, MenuItem, Select, Tooltip } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles(theme => ({
  hideIconPadding: {
    '& .MuiSelect-select': {
      position: 'relative',
      paddingRight: 0,
      zIndex: 10,
    },
  },
  selected: {
    backgroundColor: '#004EAC !important',
    color: 'white',
    fontWeight: 600,
  },
}));

export default function SelectLanguage() {
  const [option, setOption] = React.useState(() => {
    const valueInLocalStorage = window.localStorage.getItem('selectedLanguage');
    if (valueInLocalStorage) {
      return JSON.parse(valueInLocalStorage);
    }
    return 'fi';
  });
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const handleTooltipOpen = bool => setTooltipOpen(bool);

  const handleChangeOption = event => {
    const { value } = event.target;
    setOption(value);
    i18n.changeLanguage(value);

    return window.localStorage.setItem('selectedLanguage', JSON.stringify(value));
  };

  return (
    <Tooltip title={t('Language')} open={tooltipOpen} className="d-none d-sm-inline-flex">
      <Select
        className={classes.hideIconPadding}
        value=""
        disableUnderline
        IconComponent={() => (
          <LanguageIcon
            style={{
              width: 25,
              height: 25,
              cursor: 'pointer',
              position: 'absolute',
              right: '0',
              display: 'inline-block',
              color: '#545454',
            }}
          />
        )}
        onMouseEnter={() => {
          handleTooltipOpen(true);
        }}
        onMouseLeave={() => {
          handleTooltipOpen(false);
        }}
        onMouseDown={() => {
          handleTooltipOpen(false);
        }}
        onChange={handleChangeOption}
      >
        <MenuItem value="fi" className={`${option === 'fi' ? classes.selected : ''}`}>
          Suomi
        </MenuItem>
        <MenuItem value="en" className={`${option === 'en' ? classes.selected : ''}`}>
          English
        </MenuItem>
      </Select>
    </Tooltip>
  );
}
