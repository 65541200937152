import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationFI from './locales/fi/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fi: {
    translation: translationFI,
  },
};

const getSelectedLanguage = () => {
  const valueInLocalStorage = window.localStorage.getItem('selectedLanguage');
  if (valueInLocalStorage) {
    return JSON.parse(valueInLocalStorage);
  }
  return 'fi';
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getSelectedLanguage(),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
