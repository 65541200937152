import React from 'react';
import styled from 'styled-components';

import useCountdown from '../hooks/useCountDown';

const OpeningCountdown = ({ setIsOpen }) => {
  const open_date = '2024-04-04T08:00:00.000Z';
  // const open_date = '2024-04-03T17:55:00.000Z';
  const [days, hours, minutes, seconds] = useCountdown(open_date);

  React.useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      setIsOpen(true);
    }
  }, [days, hours, minutes, seconds, setIsOpen]);

  return (
    <Wrapper>
      <h1>Opening soon</h1>
      <CountDownWrapper>
        <Time name="days" value={days} />
        <Time name="hours" value={hours} />
        <Time name="minutes" value={minutes} />
        <Time name="seconds" value={seconds} />
      </CountDownWrapper>
    </Wrapper>
  );
};
export default OpeningCountdown;

const Time = ({ name, value }) => {
  return (
    <TimeWrapper>
      <p className="time">{value > 0 ? value : 0}</p>
      <p className="name">{name}</p>
    </TimeWrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    text-transform: uppercase;
  }
`;

const CountDownWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const TimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .time {
    font-size: 2rem;

    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }

  .name {
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
`;
