import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import { firestoreConnect } from 'react-redux-firebase';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { addToDataBase, deleteFromDataBase } from '../redux/actions/dataBase';
import Notes from './Notes';
import Tags from './Tags';
import Review from './Review';
import Rating from './Rating';
import * as Helper from '../util/Helper.js';
import { withStyles } from '@material-ui/core/styles';
import Button from '../inputs/Button';
import Modal from '../modal';
import { withTranslation } from 'react-i18next';

import LockIcon from '@material-ui/icons/Lock';
import InfoIcon from '@material-ui/icons/Info';
import PublicIcon from '@material-ui/icons/Public';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Tooltip from '@material-ui/core/Tooltip';

// import Comments from './Comments/index';
import { Box, Grid } from '@material-ui/core';
// import ViewPdfContentModal from './element-tabs/ViewPdfContentModal';

const styles = theme => ({
  tabs: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    minHeight: '2rem',
    borderBottom: '1px solid #e0e2e4',
  },
  tab: {
    fontSize: '0.875rem',
    fontFamily: 'HarmoniaSansStd !important',
    padding: 0,
    textAlign: 'left',
    minWidth: 0,
    minHeight: 24,
    marginBottom: 0,
    marginRight: 0,
    fontWeight: 700,
  },
  tabLabel: {
    padding: '0.25rem 1rem',
    textTransform: 'capitalize',
    color: theme.modeColors.textColor,
  },
  selected: {
    color: `${theme.modeColors.selectedColor} !important`,
  },
  tooltip: {
    fontSize: '0.875rem',
    padding: '8px 12px',
  },
  flexContainer: {
    paddingBottom: '7px',
  },
});

class ElementTabs extends Component {
  state = {
    tab: 0,
    summary: false,
    description: false,
  };

  componentDidMount = () => {
    this.getTabValue();
  };

  onChangeTab = (e, v) => {
    this.setState({ tab: v });
    window.localStorage.setItem('selectedTab', JSON.stringify(v));
  };

  getTabValue = () => {
    const valueInLocalStorage = window.localStorage.getItem('selectedTab');
    if (valueInLocalStorage) {
      return this.setState({ tab: JSON.parse(valueInLocalStorage) });
    }
    return this.setState({ tab: 1 });
  };

  filterSize = v => {
    let number;
    if (v.includes(' m2')) {
      number = v.replace(' m2', '');
    } else if (v.includes(' gfm2')) {
      number = v.replace(' gfm2', '');
    } else if (v.includes(' spaces')) {
      number = v.replace(' spaces', '');
    } else if (v.includes(' persons')) {
      number = v.replace(' persons', '');
    } else if (v.includes(' jobs')) {
      number = v.replace(' jobs', '');
    } else {
      number = v;
    }

    return Number.isInteger(parseFloat(number)) ? number : Number(number).toFixed(2);
  };

  openSumarry = () => this.setState({ summary: true });
  closeSumarry = () => this.setState({ summary: false });

  openDescription = () => this.setState({ description: true });
  closeDescription = () => this.setState({ description: false });
  createMarkup(data) {
    const dataArr = data.split('\n');

    return dataArr.map((data, index) => `<p key=des-${index}>${data}</p>`).join('');
  }

  render() {
    const { tab, description, summary } = this.state;
    const readOnly = process.env.REACT_APP_READ_ONLY;
    const {
      classes,
      proposal,
      proposalId,
      PublicTags,
      profile,
      proposalsData,
      t,
    } = this.props;
    const proposalPublicNote = Helper.getProposalPublicNote(proposalsData, proposalId);
    const proposalPrivateNote = Helper.getProposalPrivateNote(profile, proposalId);
    const proposalPublicReview = Helper.getProposalPublicReview(
      proposalsData,
      proposalId,
      'en'
    );
    const proposalPublicReviewFI = Helper.getProposalPublicReview(
      proposalsData,
      proposalId,
      'fi'
    );
    const users = readOnly ? null : this.props.users;

    const proposalPrivateRivew = Helper.getProposalPrivateReview(profile, proposalId);
    const proposalPublicTags = Helper.getProposalPublicTags(proposalId, proposalsData);
    const proposalPrivateTags = Helper.getProposalPrivateTags(profile, proposalId);
    const privateTags = _.get(profile, 'PrivateTags', []);
    const proposalPrivateRate = Helper.getProposalPrivateRate(profile, proposalId);
    const proposalPublicRate = Helper.getProposalPublicRate(proposalsData, proposalId);
    const proposalAllRate = !readOnly && Helper.getProposalAllRate(users, proposalId);
    const infoTabIndex = readOnly ? tab === 0 : tab === 2;
    const commentTabIndex = readOnly ? tab === 1 : tab === 3;

    return (
      <>
        {!readOnly ? (
          <Tabs
            value={tab}
            classes={{ root: classes.tabs, flexContainer: classes.flexContainer }}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onChangeTab}
          >
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Private">
              <Tab
                label={<LockIcon />}
                classes={{
                  root: classes.tab,
                  selected: classes.selected,
                  wrapper: classes.tabLabel,
                }}
              />
            </Tooltip>

            <Tooltip classes={{ tooltip: classes.tooltip }} title="Common">
              <Tab
                label={<PublicIcon />}
                classes={{
                  root: classes.tab,
                  selected: classes.selected,
                  wrapper: classes.tabLabel,
                }}
              />
            </Tooltip>

            <Tooltip classes={{ tooltip: classes.tooltip }} title="Info">
              <Tab
                label={<InfoIcon />}
                classes={{
                  root: classes.tab,
                  selected: classes.selected,
                  wrapper: classes.tabLabel,
                }}
              />
            </Tooltip>

            {/* <Tooltip classes={{ tooltip: classes.tooltip }} title="Comments">
              <Tab
                label={<ChatBubbleOutlineIcon />}
                classes={{
                  root: classes.tab,
                  selected: classes.selected,
                  wrapper: classes.tabLabel,
                }}
              />
            </Tooltip> */}
          </Tabs>
        ) : (
          <Tabs
            value={tab}
            classes={{ root: classes.tabs, flexContainer: classes.flexContainer }}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onChangeTab}
          >
            <Tab
              label={t('Info')}
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel,
              }}
            />
            {/* <Tab
              label={t('Comments')}
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel,
              }}
            /> */}
          </Tabs>
        )}

        {tab === 0 && !readOnly && (
          <div>
            <div className="py-2 border-bottom">
              <Notes
                text={proposalPrivateNote}
                edit={true}
                title="My quick notes"
                onSave={note => Helper.updatePrivateNote(note, proposalId, this.props)}
              />
            </div>

            <div className="py-2 border-bottom">
              <Review
                edit={true}
                title={profile.role !== 'judge' ? 'Jury review setup' : 'My review'}
                proposal={proposal}
                onSave={review =>
                  Helper.updatePrivateReview(review, proposalId, this.props)
                }
                review={proposalPrivateRivew}
              />
            </div>

            <div className="py-2 border-bottom">
              <Tags
                title={profile.role !== 'judge' ? 'Jury tags setup' : 'My tags'}
                edit={true}
                tags={privateTags}
                proposalTags={proposalPrivateTags}
                onCreate={tag =>
                  Helper.createPrivateTag(
                    tag,
                    privateTags,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                removeTag={tagId =>
                  Helper.removePrivateTag(tagId, privateTags, this.props)
                }
                addTagToProposal={tag =>
                  Helper.addPrivateTagToProposal(
                    tag,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                removeTagFromProposal={tagId =>
                  Helper.removePrivateTagFromProposal(
                    tagId,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                proposal={proposal}
              />
            </div>
            <div className="pt-2 pb-1 border-bottom">
              <Rating
                title={profile.role !== 'judge' ? 'Jury rating setup' : 'My rating'}
                edit="true"
                proposal={proposal}
                onSave={
                  profile.role !== 'judge'
                    ? rate => Helper.updatePublicRate(rate, proposalId, this.props)
                    : rate => Helper.updatePrivateRate(rate, proposalId, this.props)
                }
                rate={profile.role !== 'judge' ? proposalPublicRate : proposalPrivateRate}
                allRates={proposalAllRate}
                secretary={profile.role !== 'judge'}
              />
            </div>
          </div>
        )}

        {tab === 1 && !readOnly && (
          <div>
            <div className="py-2 border-bottom">
              <Notes
                text={proposalPublicNote}
                title="Secretary notes"
                edit={profile.role !== 'judge'}
                onSave={note => Helper.updatePublicNote(note, proposalId, this.props)}
              />
            </div>

            <div className="py-2 border-bottom">
              <Review
                edit={profile.role !== 'judge'}
                title="Jury review"
                proposal={proposal}
                onSave={review =>
                  Helper.updatePublicReview(
                    review,
                    proposalId,
                    this.props,
                    this.props.i18n.language
                  )
                }
                review={proposalPublicReview}
                reviewFI={proposalPublicReviewFI}
                enableLang
              />
            </div>

            <div className="py-2 border-bottom">
              <Tags
                title="Jury tags"
                edit={profile.role !== 'judge'}
                onCreate={tag =>
                  Helper.createPublicTag(tag, this.props, proposalId, proposalPublicTags)
                }
                tags={PublicTags ? PublicTags : []}
                addTagToProposal={tag =>
                  Helper.addPublicTagToProposal(
                    tag,
                    proposalId,
                    this.props,
                    proposalPublicTags
                  )
                }
                proposalTags={proposalPublicTags}
                removeTag={tagId => Helper.removePublicTag(tagId, this.props)}
                removeTagFromProposal={tagId =>
                  Helper.removePublicTagFromProposal(
                    tagId,
                    proposalId,
                    proposalPublicTags,
                    this.props
                  )
                }
                proposal={proposal}
              />
            </div>
            <div className="pt-2 pb-1 border-bottom">
              <Rating title="Jury rating" proposal={proposal} rate={proposalPublicRate} />
            </div>
          </div>
        )}

        {infoTabIndex && (
          <div className="py-2">
            {/* {_.isEmpty(proposal.excelData) ? <div>No description</div> : null} */}

            {renderScopeDataView(proposal, t)}

            <div>
              {/* {!!proposal.descriptions.length && proposal.descriptions[1] && (
                <Button
                  style={{
                    width: '100%',
                    marginTop: '2rem',
                    padding: '0.7rem 1rem 0.5rem',
                  }}
                  size="md"
                  variant="contained"
                  color="primary"
                  onClick={this.openSumarry}
                >
                  Description summary
                </Button>
              )} */}
              <Button
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  padding: '0.7rem 1rem 0.5rem',
                }}
                size="md"
                variant="contained"
                color="primary"
                onClick={this.openDescription}
              >
                {t('Description')}
              </Button>
              {/* <ViewPdfContentModal downloadUrl={PDF_URL[proposalId]} /> */}
            </div>
          </div>
        )}

        {/* {commentTabIndex && (
          <div className="py-2">
            <Comments proposalId={proposalId} />
          </div>
        )} */}

        {/* the Summary description and Description fetch from the excelData.description by index it should change on the future */}
        {!!proposal.descriptions.length && proposal.descriptions[1] && (
          <Modal
            close={this.closeSumarry}
            isOpen={summary}
            title="Summary description"
            proposal={proposal}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: getDescriptionShort(proposal),
              }}
            />
          </Modal>
        )}
        {!!proposal.descriptions.length && proposal.descriptions[0] && (
          <Modal
            close={this.closeDescription}
            isOpen={description}
            title={t('Description')}
            proposal={proposal}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: getDescriptionLong(proposal),
              }}
            />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.firebase.auth.uid,
    profile: state.firebase.profile,
    PublicTags: state.firestore.ordered.PublicTags,
    users: state.firestore.ordered.users,
    proposalsData: state.firestore.ordered.proposals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'users' },
    { collection: 'proposals' },
    { collection: 'PublicTags', orderBy: ['position', 'asc'] },
  ])
)(withSnackbar(withStyles(styles)(withTranslation()(ElementTabs))));

export function checkNumberIsFloat(value) {
  return Number(value) === value && value % 1 !== 0;
}

function getDescriptionShort(proposal) {
  const shortTextObj =
    proposal.descriptions.length > 0
      ? proposal.descriptions.find(element => Boolean(element.short))
      : {};
  const html = !_.isEmpty(shortTextObj)
    ? shortTextObj.short
    : '<p>No description summary</p>';

  return html;
}

function getDescriptionLong(proposal) {
  const longTextObj =
    proposal.descriptions.length > 0
      ? proposal.descriptions.find(element => Boolean(element.long))
      : {};
  const html = !_.isEmpty(longTextObj) ? longTextObj.long : '<p>No description</p>';

  return html;
}

export function renderScopeDataView(proposal, t) {
  return proposal?.excelData?.competitionEntry[t('EN')].length > 0 ? (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        <Box height="20px" mb={2} fontSize={12} fontWeight={700}>
          {proposal.excelData.headers[t('EN')][0]}
        </Box>
      </Grid>

      <Grid item xs={5}>
        <Box height="20px" mb={2} fontSize={12} textAlign="right" fontWeight={700}>
          {proposal.excelData.headers[t('EN')][2]}
        </Box>
      </Grid>

      {proposal.excelData.competitionEntry[t('EN')].map(
        ({ name, value, unit, subCategories }) => (
          <React.Fragment key={name}>
            <React.Fragment>
              <Grid item xs={8}>
                <Box mb="5px" key={name} fontSize={12}>
                  {name}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box mb="5px" fontSize={12} textAlign="right">
                  <span>{checkNumberIsFloat(value) ? value.toFixed(2) : value}</span>
                  {/* <span style={{ display: 'inline-block', marginLeft: 5 }}>
                    {unit === 'units' ? t('units') : t('sqm')}
                  </span> */}
                </Box>
              </Grid>
            </React.Fragment>

            {!!subCategories ? (
              <Grid container spacing={1} style={{ margin: 0 }}>
                {subCategories.map(({ name, value }) => (
                  <React.Fragment key={name}>
                    <Grid item xs={8}>
                      <Box mb="5px" key={name} fontSize={10} pl="10px">
                        {name}
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box mb="5px" fontSize={10} textAlign="right">
                        {checkNumberIsFloat(value) ? value.toFixed(2) : value}
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            ) : null}
          </React.Fragment>
        )
      )}
    </Grid>
  ) : (
    <p>No scope data</p>
  );
}
