import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Box } from '@material-ui/core';
// import TotalCommentsTag from './TotalCommentsTag';
// import TotalLike from '../like/TotalLike';

const SmProposal = ({
  classes,
  seen,
  proposal,
  proposalClass,
  reactAppUrl,
  totalComments,
  proposalId,
}) => (
  <Card className={classNames(classes.card, { [classes.cardBorder]: !seen })}>
    <CardActionArea
      classes={{
        root: classes.actionArea,
        focusHighlight: classes.focusHighlight,
      }}
    >
      <CardMedia
        className={classes.cardMedia}
        component="img"
        height="80"
        image={reactAppUrl + proposal.thumbnail_url}
      />
      <CardContent className="py-1 px-2">
        <div className="d-flex justify-content-between align-items-center">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <div>
              <h3 className="mb-0 mr-3">{proposal.number}</h3>
            </div>
            <Box textAlign="right">
              <h5 className={classes.truncate}>{proposal.name}</h5>
            </Box>
          </Box>
          {proposalClass && proposalClass.class.trim() === '2. vaiheeseen' && (
            <div
              className={
                proposalClass && proposalClass.class.trim() === '2. vaiheeseen'
                  ? classes.classStyle2
                  : classes.classStyle
              }
              style={{ backgroundColor: proposalClass.colour }}
            >
              {proposalClass.class}
            </div>
          )}
          {/* <div
            style={{
              position: 'absolute',
              top:
                proposalClass && proposalClass.class.trim() === '2. vaiheeseen' ? 25 : 5,
              right: 12,
              display: 'flex',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <TotalLike proposalId={proposalId} />
            <TotalCommentsTag proposalId={proposalId} />
          </div> */}
        </div>
      </CardContent>
    </CardActionArea>
  </Card>
);

SmProposal.propTypes = {
  classes: PropTypes.object.isRequired,
  reactAppUrl: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
  };
};

const styles = theme => ({
  card: {
    width: '9.125rem',
    height: '7.25rem',
    borderRadius: 0,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.modeColors.cardBackground,
  },
  cardBorder: {
    width: '8.875rem',
    height: '7rem',
    border: '0.125rem solid #f46b1e',
    boxShadow: 'none',
  },
  actionArea: {
    marginLeft: '-7px',
    marginRight: '-7px',
    width: 'calc(100% + 14px)',
    '&:hover $focusHighlight': {
      opacity: 1,
    },
  },
  cardMedia: {
    height: '5rem',
  },
  focusHighlight: {
    backgroundColor: 'rgba(247, 248, 249, 0.8)',
    height: '5rem',
  },
  classStyle: {
    position: 'absolute',
    top: 0,
    left: '7px',
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '0.25rem 0.625rem 0.125rem',
    color: '#fff',
    backgroundColor: '#ffa632',
    zIndex: 2,
  },
  classStyle2: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '0.25rem 0.75rem 0.125rem',
    color: '#fff',
    backgroundColor: '#ffa632',
    zIndex: 2,
  },
  truncate: {
    width: '50px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0,
    fontWeight: 400,
  },
});

export default connect(mapStateToProps)(withStyles(styles)(SmProposal));
