import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Button from '../inputs/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Filters from './filters';
import Zoom from './Zoom';
import ViewMode from './ViewMode';
import Sort from './Sort';
import ProposalCards from './ProposalCards';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Element from '../single-proposal/Element';
import Elements from '../single-proposal/Elements';
import SearchDetails from './SearchDetails';
// import Onboarding from './Onboarding';
import ReactToPrint from 'react-to-print';
import CompareBar from './CompareBar';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ExportPdf from './ExportPdf';
import { sortProposals, setActiveView } from '../redux/actions/proposalsActions';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  tabs: {
    position: 'relative',
    top: '-34px',
    minHeight: 0,
    width: '220px',
    display: 'inline-block',
  },
  tab: {
    fontWeight: 700,
    fontSize: '1rem',
    minWidth: 0,
    minHeight: 0,
    textTransform: 'capitalize',
    padding: '0 0.4rem',
  },
  tabLabel: {
    padding: '0px 4px',
    color: theme.modeColors.textColor,
  },
  textModeColors: {
    color: theme.modeColors.proposalTitle,
  },
  titleMargin: {
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1rem',
    },
  },
  printButton: {
    color: '#fff',
    backgroundColor: '#3f51b5',
    fontSize: '0.875rem',
    padding: '0.7rem 1rem 0.5rem',
    cursor: 'pointer',
    fontWeight: 700,
    marginRight: 16,
  },
  compareBar: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '12px 32px',
    boxSizing: 'border-box',
    position: 'fixed',
    top: '40px',
    zIndex: 100000,
    width: '100%',
  },
  minHeight: {
    minHeight: '50px',
  },
  responsive: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
});

const downloadButton = {
  color: '#fff',
  backgroundColor: '#3f51b5',
  fontSize: '0.875rem',
  padding: '0.7rem 1rem 0.5rem',
  cursor: 'pointer',
  fontWeight: 700,
  marginRight: 16,
  minWidth: 100,
};

class Proposals extends Component {
  state = {
    zoom: 3,
    chossenTab: 0,
    isModalOpen: false,
    singleElement: null,
    isTourOpen: true,
    proposalLink: '',
    proposalName: '',
    expandAll: false,
    activeComparing: false,
    compareElements: [],
    rateImages: false,
    downloadRates: false,
  };

  zoomIn = () => {
    const { zoom } = this.state;
    const maxZoom = window.innerWidth <= 414 ? 4 : window.innerWidth <= 736 ? 5 : 6;

    if (zoom < maxZoom) {
      this.setState({ zoom: zoom + 1 });
    }
  };

  zoomOut = () => {
    const { zoom } = this.state;
    if (zoom > 1) {
      this.setState({ zoom: zoom - 1 });
    }
  };

  handleChange = (e, value) => {
    this.setState({ chossenTab: value });
  };

  handleChangeIndex = index => {
    this.setState({ chossenTab: index });
  };

  openSingleElement = (val, key, proposal) => {
    this.setState({
      isModalOpen: true,
      singleElement: val,
      id: key,
      proposalLink: proposal[0],
      proposalName: proposal[1].number,
    });
  };

  addToCompare = (val, key, proposal) => {
    const { compareElements } = this.state;
    if (compareElements.length < 3 && !compareElements.includes(key)) {
      const newElemnts = [...compareElements, key];
      this.setState({ compareElements: newElemnts });
    } else if (compareElements.includes(key)) {
      const newElemnts = compareElements.filter(id => id !== key);
      this.setState({ compareElements: newElemnts });
    }
  };

  componentDidMount() {
    window.localStorage.setItem('search', this.props.location.search);

    // Test for mobile
    if (window.innerWidth <= 600) {
      this.setState({ zoom: 2 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      window.localStorage.setItem('search', this.props.location.search);
      sortProposals(5);
    }
  }

  closeModal = () => {
    const { history, location } = this.props;
    const query = queryString.parse(location.search);
    this.setState({ isModalOpen: false });
    history.push({
      search: `search=${query.search}`,
    });
  };

  toggleView = e => {
    this.props.setActiveView(e);
  };

  toggleAllRows = () => {
    this.setState({ expandAll: !this.state.expandAll });
  };

  openComparing = () => {
    this.setState({ activeComparing: true });
  };

  closeComparing = () => {
    this.setState({ activeComparing: false, compareElements: [] });
  };

  getComparingElements = () => {
    const { proposals } = this.props;
    const { compareElements } = this.state;
    const allElemets = proposals.advanced
      ? proposals.advancedElements
      : proposals.searchElements;
    return _.pickBy(allElemets, (value, key) => compareElements.includes(key));
  };

  removeFromCompare = i => {
    const { compareElements } = this.state;
    const newArray = _.filter(compareElements, key => key !== i);
    this.setState({ compareElements: newArray });
  };

  goToComparing = () => {
    const { compareElements } = this.state;
    this.props.history.push(`/comparing?elements=${compareElements.join('%2C')}`);
  };

  paint = (Painter, availableWidth, availableHeight) => {
    Painter.path(
      'M5,31.799999999999997C15.933333333333332,58.599999999999994,26.866666666666667,85.4,37.8,85.4C48.73333333333333,85.4,59.666666666666664,85.4,70.6,85.4C81.53333333333333,85.4,92.46666666666665,5,103.39999999999999,5C114.33333333333333,5,125.26666666666665,112.2,136.2,112.2C147.13333333333333,112.2,158.06666666666666,58.60000000000001,169,5'
    );
    Painter.lineWidth(4);
    Painter.strokeColor('#3f51b5');
    Painter.stroke();
    //<Canvas style={{width: 500, height: 300}} paint={paint}/>
  };

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY;
    const {
      proposals,
      history,
      classes,
      proposalsOrdered,
      PublicClasses,
      selectedPhase,
      activeView,
      t,
    } = this.props;
    //const rates = this.getRatingImages(proposals);
    const { chossenTab, expandAll, activeComparing, compareElements } = this.state;
    const styleOverrides = `
      .MuiTableCell-head {
        width: 40px !important;
        max-width: 40px !important;
        font-size: 8px !important;
        line-height: 12px !important;
      }
      .MuiTableCell-head:nth-child(1), .MuiTableCell-head:nth-child(2), .MuiTableCell-head:nth-child(3) {
        width: 40px !important;
        max-width: 40px !important;
        position: relative !important;
        left: auto !important;
      }
      .MuiTableCell-body:nth-child(3) > div > div {
        width: 40px !important;
        max-width: 40px !important;
        font-size: 8px;
        overflow-wrap: break-word !important;
      }
      .MuiTableCell-body {
        width: 40px !important;
        max-width: 40px !important;
        font-size: 10px !important;
        overflow-wrap: break-word !important;
      }
      .MuiTableCell-body:nth-child(1), .MuiTableCell-body:nth-child(2), .MuiTableCell-body:nth-child(3) {
        position: relative !important;
        left: auto !important;
      }
      .MuiTableCell-head {
        min-width: 0 !important;
      }
      .MuiTableCell-body:nth-child(3), .MuiTableCell-body:nth-child(3) {
        box-shadow: none !important;
      }
    `;

    if (proposals.loading || _.isEmpty(proposals)) {
      return <div>Loading</div>;
    } else {
      if (proposals.err) {
        return <div>Error</div>;
      }
      return (
        <div>
          {activeComparing && (
            <CompareBar
              goToComparing={this.goToComparing}
              removeFromCompare={this.removeFromCompare}
              compareElements={this.getComparingElements()}
              closeComparing={this.closeComparing}
            />
          )}

          {!readOnly && <Filters />}

          <div className="pt-3 px-3">
            <div className={`mb-2 ${classes.minHeight} ${classes.responsive}`}>
              <h3 className={`${classes.textModeColors} ${classes.titleMargin}`}>
                {/* Default display */}
                {!history.location.search && proposals.data && (
                  <span>
                    {t('All proposals')}: {Object.keys(proposals.data).length}
                  </span>
                )}

                {/* Filter class multi-select dropdown */}
                {history.location.search && !proposals.search && (
                  <span>Result: {Object.keys(proposals.data).length}</span>
                )}

                {/* Advanced search by elements */}
                {history.location.search && proposals.search && (
                  <span>
                    Result: {''}
                    {Object.keys(proposals.searchElements).length +
                      Object.keys(proposals.searchProposals).length}
                  </span>
                )}
              </h3>

              <div className="d-flex align-items-center">
                {((chossenTab === 0 && proposals.search) ||
                  (!proposals.search && !proposals.advanced)) && (
                  <>
                    {activeView === 'list' && (
                      <div className="d-flex">
                        {((!proposals.search &&
                          !proposals.advanced &&
                          activeView === 'list') ||
                          (proposals.search &&
                            activeView === 'list' &&
                            chossenTab === 0)) && (
                          <div className="mr-2">
                            <Button
                              size="lg"
                              onClick={this.toggleAllRows}
                              color="primary"
                            >
                              {expandAll ? 'Collapse' : 'Expand'} all rows
                            </Button>
                          </div>
                        )}

                        <ReactToPrint
                          trigger={() => (
                            <div className={classes.printButton}>Print list</div>
                          )}
                          content={() => this.componentRef}
                        />

                        {false && (
                          <PDFDownloadLink
                            document={
                              <ExportPdf
                                data={proposals.initData}
                                proposalsOrdered={proposalsOrdered}
                                PublicClasses={PublicClasses}
                              />
                            }
                            fileName={'all proposals.pdf'}
                            style={downloadButton}
                          >
                            {({ blob, url, loading, error }) =>
                              loading
                                ? 'Loading document...'
                                : 'Export all proposal cards'
                            }
                          </PDFDownloadLink>
                        )}
                      </div>
                    )}

                    {activeView === 'table' && (
                      <ReactToPrint
                        trigger={() => (
                          <div className={classes.printButton}>Print table</div>
                        )}
                        content={() => this.componentRef}
                        pageStyle={styleOverrides}
                      />
                    )}

                    <ViewMode
                      toggleView={this.toggleView}
                      activeView={activeView}
                      isSecondPhase={selectedPhase === 'phase-2'}
                    />
                    <Sort />
                  </>
                )}

                {((chossenTab === 1 && proposals.search) || proposals.advanced) && (
                  <Button
                    onClick={this.openComparing}
                    size="md"
                    variant="outlined"
                    color="primary"
                  >
                    Select elements to compare
                  </Button>
                )}

                <Zoom
                  zoomIn={this.zoomIn}
                  zoomOut={this.zoomOut}
                  zoomLevel={this.state.zoom}
                />
              </div>
            </div>

            <SearchDetails search={history.location.search} data={proposals} />
            {(proposals.search || proposals.advanced || activeView !== 'list') && (
              <span></span>
            )}

            {!proposals.search && !proposals.advanced && (
              <div ref={el => (this.componentRef = el)} className="mt-4">
                <ProposalCards
                  listMode={activeView === 'list'}
                  tableMode={activeView === 'table'}
                  videoMode={activeView === 'videos'}
                  history={history}
                  Data={proposals.data}
                  zoomLevel={this.state.zoom}
                  expandAll={expandAll}
                />
              </div>
            )}

            {proposals.search && (
              <div className="mt-4">
                <Tabs
                  value={chossenTab}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  classes={{ root: classes.tabs }}
                >
                  <Tab
                    label="Proposals"
                    classes={{ root: classes.tab, wrapper: classes.tabLabel }}
                  />
                  <Tab
                    label="Elements"
                    classes={{ root: classes.tab, wrapper: classes.tabLabel }}
                  />
                </Tabs>

                <div>
                  {chossenTab === 0 && (
                    <div ref={el => (this.componentRef = el)}>
                      <ProposalCards
                        listMode={activeView === 'list'}
                        history={history}
                        Data={proposals.searchProposals}
                        zoomLevel={this.state.zoom}
                        expandAll={expandAll}
                      />
                    </div>
                  )}
                  {chossenTab === 1 && (
                    <Grid
                      container
                      className="px-1"
                      justifyContent="flex-start"
                      spacing={2}
                    >
                      <Elements
                        zoom={this.state.zoom}
                        elements={proposals.searchElements}
                        testElements={proposals.searchElementsTest}
                        openSingleElement={
                          activeComparing ? this.addToCompare : this.openSingleElement
                        }
                        compareElements={compareElements}
                        proposalLink={this.state.proposalLink}
                      />
                    </Grid>
                  )}
                </div>
              </div>
            )}

            {proposals.advanced && (
              <Grid
                container
                className="px-1 mt-4"
                justifyContent="flex-start"
                spacing={2}
              >
                <Elements
                  zoom={this.state.zoom}
                  elements={proposals.advancedElements}
                  openSingleElement={
                    activeComparing ? this.addToCompare : this.openSingleElement
                  }
                  compareElements={compareElements}
                />
              </Grid>
            )}
          </div>
          <Element
            id={this.state.id}
            element={this.state.singleElement}
            elements={
              proposals.search ? proposals.searchElements : proposals.advancedElements
            }
            close={this.closeModal}
            isOpen={this.state.isModalOpen}
            link={this.state.proposalLink}
            proposalName={this.state.proposalName}
            openSingleElement={this.openSingleElement}
            expand
          />
          {/* <Onboarding /> */}
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users,
    proposals: state.proposals,
    selectedPhase: state.proposals.selectedPhase,
    activeView: state.proposals.activeView,
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsOrdered: state.firestore.ordered.proposals,
  };
};

export default connect(mapStateToProps, { setActiveView })(
  withTranslation()(withStyles(styles)(Proposals))
);
